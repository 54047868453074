form{
    margin:2rem 6rem 2rem 4rem;
     overflow-x: hidden;
     padding:2rem 2rem 2rem 2rem;
     border: 1px solid #BFBFBF;
     border-radius: 5px;
     max-width:90%;
     box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) ;

}
iframe{ margin:2rem 6rem 2rem 4rem;
 overflow-x: hidden;
 padding:2rem 2rem 2rem 2rem;
 border: 1px solid #BFBFBF;
 border-radius: 5px;
 max-width:90%;
 box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) ;
}
@media(max-width:720px){
 form{
     margin:2rem 0rem 2rem 1rem !important;
      overflow-x: hidden;
      padding:2rem;
      border: 1px solid #BFBFBF;
      border-radius: 3px;
     height: 30rem;

}
iframe{
  margin:2rem 0rem 2rem 1rem !important;
  overflow-x: hidden;
  padding:2rem;
  border: 1px solid #BFBFBF;
  border-radius: 3px;
  height: 30rem;
 }

}