
 
.successmain{
    background-image: url("../images/yf4.jpg");
    height: 30rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    
   
}
.successcontent
{
  position: absolute;
  top: 23rem;
  left: 5rem;
  opacity: 0;
  width: 50%;
  color: #fff;
  padding: 3rem;
  background: rgba(0, 0, 0, 0.3);
  animation: slide-up 1s ease 0.5s;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes slide 
{
    from
    {
     width: 250px;
    }
     to
    {
      width: 1300px;
    }
}