
 .Carousel > img{
    height: 5% !important;
    max-width:70%;
    margin: 2%;
    margin-left: 0rem;
    
}
img.d-block.w-100{
    height: 35rem;
    opacity: 0.7;
}
 


Carousel.Caption{
    padding-top: 10rem;
}

.carousel-item{
    margin: 5%;
}
.homeheading{
    color: rgb(204,18,18);


}
.homeparagraph{
 color: black;
 font-size: 1.5rem;
 
 margin-bottom: 10rem;
 
}

@media (max-width:720px){
    
    .homeparagraph{
        font-size: small !important;
        margin-bottom: 4rem;
    }
    .homeheading{
        font-size: small !important;
        
        
    }
    img.d-block.w-100{
        height: 22rem;
        opacity: 0.7;
    }

}