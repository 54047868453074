.home__hero-section {
  color: #fff;
  padding: 4rem 0;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0;
  max-width: 50%;
  flex-basis: 50%;
}

.home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: rgb(204,18,18);
  font-style: oblique;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.heading {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
  
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.home__hero-subtitle {
  max-width: 22rem;
  margin-bottom: 2.2rem;
  font-size: 1rem;
  line-height: 1.6rem;
}

.home__hero-img-wrapper {
  max-width: 30rem;
}

.home__hero-img {
  max-width: 90%;
  height: 28rem;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
  max-height: fit-content !important;
  transform: scale(1);
  transition: transform 0.5s ease;
}
.home__hero-img:hover{transform: scale(1.1);}


img {
  border: 0;
  max-width: 90%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 992px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
    
  }
  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
  .home__hero-section {
    color: #ffffff;
    padding: 1rem 0;
  }
  .home__hero-img{
    height: 22rem;
  }
  
  
}

