.app{

    overflow-x:none;
}
.section{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.main-heading{
    color: rgb(204,18,18);
    font-size: 1.5rem !important;
}
.underline{
    height: 04px;
    width: 4rem;
    background-color: #f76c2f;
    margin-top: 10px;
    margin-bottom: 10px;
}
.bg-c-light{
    background-color: #f2f2f2;
}
.serviceheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.servicemore{
    text-align: center;
}
.contactheading{
    margin-top: 4rem;
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.aboutheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
}
.who{
    font-size: 1.4rem;
}
.successheading{
    text-align: center;
    color: rgb(204,18,18);
    font-size: 1.5rem;
    margin-top: 4rem;
    margin-bottom: 1rem;

}
.successh3{
    color: white;
}
.successparagraph{
    color: white;
}
@media(max-width:720px)
{
    .who{
        font-size: 1.3rem;
    }
}